import { useDispatch, useSelector } from 'react-redux';
import { backButtonTrigger } from '../../../utils/utils';
import { NteButton } from '../../Forms/NteButton/NteButton';
import { PlplayoutConstants } from '../../Layouts/Plp/PlpLayoutConstants';
import { productListSelector } from '../../../redux/selectors/productList.selector';

/**
 * @interface PaginationProps
 */
export interface PaginationProps {
  totalProducts: number;
  isPageLoading: boolean;
  currentPageNumber: number;
  updatePageNumber: () => void;
}

/**
 *
 * @param PaginationProps.
 * @returns Pagination Load more button that loads the next page of products.
 */
const Pagination: React.FC<PaginationProps> = ({
  totalProducts,
  isPageLoading,
  currentPageNumber,
  updatePageNumber,
}) => {
  const { getProductCountPerPage, TRANSLATIONS, GRID, PLP_VIEW } =
    PlplayoutConstants;

  const isBack = backButtonTrigger();
  const dispatch = useDispatch();
  const { productList } = useSelector(productListSelector);
  const allProductsLoaded = isBack
    ? productList.length * getProductCountPerPage() >= totalProducts
    : currentPageNumber * getProductCountPerPage() >= totalProducts;

  const isPlpGridView = sessionStorage.getItem(PLP_VIEW) === GRID;

  return (
    <>
      <div
        className={`load-more-container ${
          isPlpGridView ? 'grid-product-card-load-more-spacing' : ''
        }`}>
        {!allProductsLoaded && (
          <NteButton
            onClick={updatePageNumber}
            classes={{ root: 'load-more-button' }}
            loading={isPageLoading}
            disabled={allProductsLoaded}
            variant='contained'
            size='large'
            translationKey={TRANSLATIONS.LOAD_MORE}
            type='button'
          />
        )}
      </div>
    </>
  );
};

export { Pagination };
