import { AppBar, Divider } from '@material-ui/core';

import { useCheckout } from '../Checkout/hooks/CheckoutHooks';
import { HeaderMessage } from '../HeaderBanner/HeaderMessage';
import { PrimaryNavBar } from '../Widgets/AppBar/PrimaryNavBar/PrimaryNavBar';
import { SecondaryNavBar } from '../Widgets/AppBar/SecondaryNavBar/SecondaryNavBar';
import { ReactComponent as NTE_LOGO_BLACK } from '../../theme/assets/logo_blk-gold.svg';
import { useHistory } from 'react-router';
import {
  getLocalStorage,
  pageEventTrigger,
  setLocalStorage,
} from '../../utils/utils';

import { useCurrentPathName } from '../../utils/hooks/current-pathname/CurrentPathName';

/**
 * @component NteAppBar is responsible for
 * rendering the NteAppBar component.
 */
const NteAppBar: React.FC = () => {
  const { isCheckout } = useCheckout();
  const history = useHistory();
  //(history.action === 'PUSH')&&localStorage.setItem("Back","false");

  //history.location.pathname === '/' && deleteCacheDB();

  const historyObj = history.location.pathname.split('/');

  const plppossibilities = [
    'brands',
    'categories',
    'search',
    'on-sale',
    'clearance',
    'everyday-free-shipping',
    'new-products',
    'rebates-special-offers',
  ];
  const visitedPath = getLocalStorage('VISITED_PATH') || '';
  const params = visitedPath.split('&');

  // Filter out the parameter containing 'index='
  const filteredParams = params.filter(
    (param: any) => !param.startsWith('index=')
  );

  // Join the filtered parameters back together with '&' to form the cleaned visitedPath
  const cleanedVisitedPath = filteredParams.join('&');

  console.log(cleanedVisitedPath);

  // plppossibilities.indexOf(historyObj[1]) == -1
  //   ? setLocalStorage('INIT_CACHE', false)
  //   : setLocalStorage('INIT_CACHE', true);
  const initializeOnce = getLocalStorage('INIT_CACHE') || '';
  const currentPathName = useCurrentPathName();
  const pathTrack = currentPathName.pathname + history.location.search;

  if (
    pathTrack === cleanedVisitedPath &&
    plppossibilities.indexOf(historyObj[1]) !== -1 &&
    initializeOnce !== true
  ) {
    localStorage.setItem('Back', 'true');
    //setLocalStorage("VISITED_PATH","");
  } else {
    plppossibilities.indexOf(historyObj[1]) !== -1 &&
      setLocalStorage('VISITED_PATH', pathTrack);
    localStorage.setItem('Back', 'false');
    setLocalStorage('INIT_CACHE', true);
  }

  history.action === 'PUSH' &&
    plppossibilities.indexOf(historyObj[1]) === -1 &&
    localStorage.setItem('VISITEDITEM', history.location.pathname);
  history.action === 'PUSH' &&
    plppossibilities.indexOf(historyObj[1]) !== -1 &&
    setLocalStorage('SET_FACET_ACTION', {
      selectedFacets: [],
      selectedGroupNames: [],
      selectedGroups: {},
    });
  plppossibilities.indexOf(historyObj[1]) === -1 &&
    historyObj[1] !== 'products' &&
    setLocalStorage('LAST_ACTIONCALLED', {});
  plppossibilities.indexOf(historyObj[1]) === -1 &&
    historyObj[1] !== 'products' &&
    setLocalStorage('LAST_ACTIONCALLED_UPDATE', {});
  // if (pageEvent === 'reload') {
  //   setLocalStorage('LAST_ACTIONCALLED_UPDATE', '');
  //   setLocalStorage('LAST_ACTIONCALLED', '');
  // }

  // const cachedarray = getLocalStorage("GET_PRODUCT_LIST_SUCCESS_ACTION");
  //(historyObj.length>1)&&(cachedarray.resourceId.includes(historyObj[1]))&&(history.action === 'PUSH')&&localStorage.setItem("Back","true");
  //(history.action === 'PUSH')&&(history.location.pathname.includes("deals")||historyObj.includes("categories")||historyObj.includes("Brands"))&&localStorage.setItem("Back","true");

  ///(history.action ==='REPLACE')&&searchKeyword === sValue&&localStorage.setItem("Back","true");

  return (
    <>
      <div className='print-logo'>
        {' '}
        {/* To display when user try to print the page */}
        <NTE_LOGO_BLACK aria-labelledby='NTElogo Icon' role={'img'} />
      </div>
      <div className='print-exclude'>
        {' '}
        {/* Hides the header when user prints a page */}
        <HeaderMessage />
        <AppBar position='static'>
          <div className='header_wrapper'>
            {!isCheckout && (
              <>
                {/* Secondary NavBar */}
                <SecondaryNavBar />

                {/* Divider between Primary and Secondary NavBar */}
                <Divider className='header_divider' />
              </>
            )}

            {/* Primary NavBar */}
            <PrimaryNavBar />
          </div>
        </AppBar>
      </div>
    </>
  );
};

export { NteAppBar };
