import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { SearchLayoutConstants } from '../../../components/Layouts/Search/SearchLayout.constants';
import { FacetConstants } from '../../../components/Widgets/Facets/Facet/FacetConstants';

/**
 * @method useClearQueryParams has callbacks responsible for clearing query params on route change.
 */
const useClearQueryParams = () => {
  const { CATEGORY_QUERY_KEY, FACET_QUERY_KEY, SEO_QUERY_KEY, ORDER_BY } =
    FacetConstants;

  const { SEARCH_QUERY_PARAM } = SearchLayoutConstants;

  const history = useHistory();

  const [, setFacetsParams] = useQueryParam(FACET_QUERY_KEY, StringParam);

  const [categoryQueryParam] = useQueryParam(CATEGORY_QUERY_KEY, StringParam);

  const [searchQueryParam] = useQueryParam(SEARCH_QUERY_PARAM, StringParam);

  const [, setSeoParams] = useQueryParam(SEO_QUERY_KEY, StringParam);

  /**
   * @method resetQueryParams Reset the query param for a specific key on route change.
   */

  const [encodedOrderFacetParams] = useQueryParam(ORDER_BY, StringParam);
  // const { PLP_PAGE_TYPE } = ProductCardSectionMessagesConstants;
  // if (pageType === PLP_PAGE_TYPE && encodedOrderFacetParams) {
  //   console.log('Hit');
  // }
  // debugger;

  const resetQueryParams = useCallback((): void => {
    if (!searchQueryParam && !categoryQueryParam) {
      if (!encodedOrderFacetParams) {
        setFacetsParams(undefined, 'replace');

        setSeoParams(undefined, 'replace');
      }
    }

    if (searchQueryParam || categoryQueryParam) {
      !encodedOrderFacetParams && setFacetsParams(undefined, 'replaceIn');

      setSeoParams(undefined, 'replaceIn');
    }
  }, [
    categoryQueryParam,
    searchQueryParam,
    setFacetsParams,
    setSeoParams,
    encodedOrderFacetParams,
  ]);

  useEffect(() => {
    resetQueryParams();
  }, [history.location.pathname, resetQueryParams]);
};

export { useClearQueryParams };
