import { useCallback, useEffect, useState } from 'react';
import { highLightText } from '../../../../../utils/highlight-text';
import { ProductCardSectionMessagesConstants } from '../../../Plp/ProductCard/ProductCardSectionMessages/ProductCardSectionMessagesConstants';

/**
 * @interface ProdutctShippingMessage
 */
interface ProductShippingMessage {
  label: JSX.Element;
}

/**
 * @method useShippingMessageColorHook sets the shipping color class for respective shipping message types.
 */
const useShippingMessageColorHook = (shippingMessage: string) => {
  const { ProductShippingConstants } = ProductCardSectionMessagesConstants;

  const [shippingMessageProps, setShippingMessageProps] =
    useState<ProductShippingMessage>({
      label: highLightText(
        ProductCardSectionMessagesConstants.FREE_SHIPPING_BACKORDERED,
        ProductCardSectionMessagesConstants.FREE_SHIPPING,
        ProductShippingConstants.MESSAGES.GREEN_COLOR
      ),
    });

  const [shippingStatusClassName, setShippingStatusClassName] = useState(
    ProductShippingConstants.MESSAGES.DEFAULT_COLOR
  );

  const [showBackOrderedMessage, setShowBackOrderedMessage] = useState(false);

  /**
   * @method
   * Responsible for adding the props to shipping message
   */
  const setShippingMessageClass = useCallback((): void => {
    if (shippingMessage) {
      if (
        shippingMessage
          .toLowerCase()
          .includes(ProductCardSectionMessagesConstants.AVAILABLE_NOW) ||
        shippingMessage
          .toLowerCase()
          .includes(ProductCardSectionMessagesConstants.FACTORY_SHIPPED) ||
        shippingMessage.toLowerCase() ===
          ProductCardSectionMessagesConstants.FREE_SHIPPING_LOWER_48_STATES.toLowerCase()
      ) {
        setShippingStatusClassName(
          ProductShippingConstants.MESSAGES.GREEN_COLOR
        );
        //don't exit here, continue on to catch instance where message is combined with backordered state
      }

      if (
        shippingMessage
          .toLowerCase()
          .includes(ProductCardSectionMessagesConstants.NOT_AVAILABLE)
      ) {
        setShippingStatusClassName(
          ProductShippingConstants.MESSAGES.GREY_COLOR
        );
        return;
      }

      if (
        shippingMessage.includes(
          ProductCardSectionMessagesConstants.FREE_SHIPPING_BACKORDERED
        ) ||
        shippingMessage.includes(
          ProductCardSectionMessagesConstants.BACKORDERED
        )
      ) {
        setShippingStatusClassName(
          ProductShippingConstants.MESSAGES.DEFAULT_COLOR
        );
        setShowBackOrderedMessage(true);
        setShippingMessageProps({
          label: highLightText(
            shippingMessage,
            ProductCardSectionMessagesConstants.BACKORDERED,
            ProductShippingConstants.MESSAGES.RED_COLOR
          ),
        });
        return;
      }
    }
  }, [
    shippingMessage,
    ProductShippingConstants.MESSAGES.GREEN_COLOR,
    ProductShippingConstants.MESSAGES.GREY_COLOR,
    ProductShippingConstants.MESSAGES.DEFAULT_COLOR,
  ]);

  useEffect(() => {
    setShippingMessageClass();
  }, [setShippingMessageClass, shippingMessage]);

  return {
    shippingStatusClassName,
    showBackOrderedMessage,
    shippingMessageProps,
  };
};

export { useShippingMessageColorHook };
