// UI
import { CircularProgress, ThemeProvider } from '@material-ui/core';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryParamProvider, StringParam } from 'use-query-params';
import App from './App';
/** Instantiate i18n on app start */
import './i18n';
import store from './redux/store';
import reportWebVitals from './reportWebVitals';
// APP
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ntev9 } from './theme/nte-theme';
import { searchStringToObject } from './utils/utils';
import { initDB } from './redux/reducers/indexedDB';

const FALLBACK_SPINNER = <CircularProgress size={24} color='primary' />;

const recapchaKey: string = process.env.REACT_APP_RECAPTCHA_KEY!;

const initializeApp = async () => {
  try {
    await initDB(); // Ensure database is initialized
    console.log('Database initialized successfully.');
  } catch (error) {
    console.error(
      'Failed to initialize database or perform data operations:',
      error
    );
    // Handle initialization or data operations error gracefully
  }
};

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={recapchaKey}>
      <Provider store={store}>
        <Router>
          <QueryParamProvider
            adapter={ReactRouter5Adapter}
            options={{
              updateType: 'replaceIn',
              params: {
                f: StringParam,
                c: StringParam,
                s: StringParam,
                b: StringParam,
                orderBy: StringParam,
              },
              searchStringToObject,
            }}>
            <Suspense fallback={FALLBACK_SPINNER}>
              <ThemeProvider theme={ntev9}>
                <App />
              </ThemeProvider>
            </Suspense>
          </QueryParamProvider>
        </Router>
      </Provider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

initializeApp();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
