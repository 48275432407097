import { openDB, IDBPDatabase } from 'idb';

let db: IDBPDatabase | null = null;

const DB_NAME = 'plpCacheDB';
const STORE_NAME = 'productListCache';

// Initialize the database
export const initDB = async (): Promise<boolean> => {
  try {
    db = await openDB(DB_NAME, 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains(STORE_NAME)) {
          db.createObjectStore(STORE_NAME, { keyPath: 'id' });
        }
      },
    });

    console.log('Database opened successfully.');
    return true;
  } catch (error) {
    console.error('Failed to open database:', error);
    return false;
  }
};

// Add data to the database
export const addData = async (data: any[]): Promise<string> => {
  if (!db) throw new Error('Database not initialized.');

  try {
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    //const putRequest = store.put(data, 1);

    await store.put({ id: 1, name: data }); // Using put to add or update data with key 1

    console.log('Data added successfully.');
    return 'Data added successfully';
  } catch (error) {
    console.error('Error adding data:', error);
    throw new Error('Failed to add data to database.');
  }
};

// Retrieve data from the database
export const getStoreData = async (): Promise<string> => {
  if (!db) throw new Error('Database not initialized.');

  try {
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);

    const data = await store.getAll(); // Retrieve data with key 1

    if (typeof data === 'object') {
      return data[0]?.name;
    } else {
      return 'null'; // Return null if no data found or data is not a string
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw new Error('Failed to fetch data from database.');
  }
};

//update data

export const update = async (data: any[]): Promise<string> => {
  if (!db) throw new Error('Database not initialized.');

  try {
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    //store.clear();
    //const putRequest = store.put(data, 1);

    const request = store.openCursor();

    const cursor = await request;

    if (cursor) {
      cursor.value.name = data;
      cursor.update(cursor.value);
    }

    //await store.put({ id: 1, name: data }); // Using put to add or update data with key 1
    return 'Data added successfully';
  } catch (error) {
    console.error('Error adding data:', error);
    throw new Error('Failed to add data to database.');
  }
};

// Close the database connection
export const closeDB = (): void => {
  if (db) {
    db.close();
    db = null;
    console.log('Database closed.');
  }
};
export async function fetchData() {
  try {
    const data = await getStoreData();
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export const deleteCacheDB = async () => {
  if (!db) throw new Error('Database not initialized.');

  try {
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);

    //const data = await store.getAll(); // Retrieve data with key 1

    const data = await store.clear;
    console.log(data);
    // if (typeof data === 'object') {
    //   return JSON.parse(data[0].name);
    // } else {
    //   return '{}'; // Return null if no data found or data is not a string
    // }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw new Error('Failed to fetch data from database.');
  }
};
