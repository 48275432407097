import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import { checkTruthy, formatSearchTerm } from '../../../../utils/utils';
import { USER_CONTRACT } from '../../../constants/cookie';
import { IFetchProductList } from '../../../interface/ProductList/IProductList';
import { makeRequest } from '../../axios/axiosConfig';
import { productsService } from '../products/products.service';
import { SearchSiteContentServiceConstants } from './searchSiteContent.service.constants';

/**
 * @interface
 */
interface IFetchSearchTerm {
  storeID: string;
  term: string;
  catalogId: string;
}

/**
 * Responsible to fetch the Products for the given term
 */
const searchProductsContentService = {
  async fetchProductsBySearchTerm({
    searchKeyword,
    storeID,
    pageNumber,
    pageSize,
    cancelToken,
    facet,
    maxPrice,
    minPrice,
    orderBy,
  }: IFetchProductList) {
    const { SEARCH_PRODUCTS_SERVICE_URL } = SearchSiteContentServiceConstants;

    const keyword = searchKeyword ? searchKeyword : '';

    const SEARCH_PRODUCTS_PATH = SEARCH_PRODUCTS_SERVICE_URL(storeID, keyword);

    const userContractId = sessionStorage.getItem(USER_CONTRACT);

    const queryParams = {
      pageSize,
      pageNumber,
      orderBy,
      ...{ ...(maxPrice && { maxPrice }) },
      ...{ ...(minPrice && { minPrice }) },
      ...{ ...(userContractId && { contractId: userContractId }) },
    };

    const facetQuery = facet
      ? `&${qs.stringify(
          { facet },
          {
            indices: false,
          }
        )}`
      : '';
    const source = axios.CancelToken.source();
    try {
      const request: AxiosRequestConfig = {
        url: SEARCH_PRODUCTS_PATH + facetQuery,
        method: 'GET',
        params: queryParams,
        cancelToken: source.token,
      };

      let siteResponse: any;
      siteResponse = await makeRequest(request);

      const isPdpRedirect = siteResponse.recordSetCount === 0;

      if (isPdpRedirect) {
        const pdpResponse = await productsService.fetchProductsByPartNumbers({
          storeID,
          partNumbers: [keyword],
        });

        /**
         * Redirect to PDP page
         * only if displayable is true.
         */
        if (
          pdpResponse?.contents &&
          checkTruthy(pdpResponse?.contents[0]?.displayable)
        ) {
          siteResponse = pdpResponse;
        }
      }

      return { siteResponse, isPdpRedirect };
    } catch (error) {
      console.error(error);

      throw error;
    }
  },

  /**
   * @method fetchSearchDisplayBySearchTerm
   * Responsible to fetch the searchDisplay for the search term
   */
  async fetchSearchDisplayBySearchTerm({
    storeID,
    term,
    catalogId,
  }: IFetchSearchTerm) {
    const { SEARCH_DISPLAY_SERVICE_URL } = SearchSiteContentServiceConstants;

    const SEARCH_DISPLAY_PATH = SEARCH_DISPLAY_SERVICE_URL(
      storeID,
      formatSearchTerm(term),
      catalogId
    );

    try {
      const searchDisplayRequest: AxiosRequestConfig = {
        url: SEARCH_DISPLAY_PATH,
        method: 'GET',
      };

      const searchDisplayResponse = await makeRequest(searchDisplayRequest);

      return searchDisplayResponse;
    } catch (error) {
      throw error;
    }
  },
};

export { searchProductsContentService };
