import { AxiosError } from 'axios';
import { call, put } from 'redux-saga/effects';
import {
  backButtonTrigger,
  formatSearchTerm,
  getLocalStorage,
  recentlyVisitedLinks,
  setLocalStorage,
} from '../../../utils/utils';
import { categoryService } from '../../../_foundation/apis/search/category/category.service';
import { productsService } from '../../../_foundation/apis/search/products/products.service';
import { searchProductsContentService } from '../../../_foundation/apis/search/search-siteContent/searchProducts.service';
import { CANCEL_ON_UNMOUNT } from '../../../_foundation/constants/cancel';
import { REDIRECT_TO_ERROR_PAGE_ACTION } from '../../actions/network-errors.actions';
import {
  CURRENT_BRAND_IDENTIFIER_ACTION,
  CURRENT_BRAND_ID_ACTION,
  CURRENT_BRAND_INFO_ACTION,
  FACET_LOADING_ACTION,
  FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS_ACTION,
  FETCH_INVENTORY_INFO_SUCCESS_ACTION,
  FETCH_PRIVATE_BRANDS_SUCCESS_ACTION,
  FETCH_SUB_CATEGORIES_SUCCESS_ACTION,
  GET_PRODUCT_LIST_SUCCESS_ACTION,
  NO_SEARCH_RESULTS_FOUND_ACTION,
  REDIRECT_TO_PDP_ACTION,
  SEARCH_DISPLAY_SUCCESS_ACTION,
  SEARCH_PRODUCT_LIST_ACTION,
  SET_PLP_PAGE_LOADING_ACTION,
  SHOW_PLP_ERROR_ACTION,
  UPDATE_FACETS_SUCCESS_ACTION,
} from '../../actions/productList.actions';
import {
  addData,
  fetchData,
  getStoreData,
  update,
} from '../../reducers/indexedDB';
import { cloneDeep, has } from 'lodash';

export function* getProductList(action: any): Generator {
  try {
    let response: any;
    const backAction = backButtonTrigger();
    const getStoreDataPromise_init = getStoreData();
    const fetchedData: any = yield getStoreDataPromise_init;
    let previousData: any;
    if (!backAction) {
      response = yield call(
        productsService.fetchProductsByCategory,
        action.payload
      );
      setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', response);
      if (action.payload.pageNumber >= 2) {
        // Handle cachedProductList here if needed
        let cachedProductList: any[] = [];
        if (fetchedData) {
          cachedProductList = [...fetchedData];
        }
        cachedProductList.push(response.catalogEntryView);
        const dataToAdd = cachedProductList;
        yield update(dataToAdd);
      } else {
        const dataToAdd = [response.catalogEntryView];
        yield addData(dataToAdd);
        previousData = [response.catalogEntryView];
      }
    } else {
      let cachedProductList: any[] = [];
      if (fetchedData) {
        cachedProductList = [...fetchedData];
      }
      if (cachedProductList.length < action.payload.pageNumber) {
        const { siteResponse }: any = yield call(
          productsService.fetchProductsByCategory,
          action.payload
        );
        response = siteResponse;
        setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', siteResponse);
        cachedProductList.push(response.catalogEntryView);
        yield update(cachedProductList);
      } else {
        response = getLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION');
      }
    }

    const getStoreDataPromise = getStoreData();
    previousData = yield getStoreDataPromise;
    // let cachedFacets = getLocalStorage('SET_FACET_ACTION');
    // if (cachedFacets.selectedGroups?.price && cachedFacets.selectedGroups) {
    //   yield put(
    //     SET_FACET_ACTION({
    //       facetData: cachedFacets.selectedGroups?.price,
    //       isChecked: true,
    //       facetGroup: 'Price',
    //       isPriceFacet: true,
    //       initIfPrice: 'true',
    //     })
    //   );
    // }
    yield put(
      GET_PRODUCT_LIST_SUCCESS_ACTION({
        ...response,
        userType: action.payload.userType,
        isBrandPlp: action.payload.isBrandPlp,
        searchPlp: false,
        pageNumber: action.payload.pageNumber,
        previousData: previousData,
      })
    );
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));

      return;
    }

    console.error(e);

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* filterProductList(action: any): Generator {
  try {
    const pageNumber = action.payload.pageNumber;

    if (pageNumber === 0) {
      yield put(FACET_LOADING_ACTION(true));
    } else {
      yield put(SET_PLP_PAGE_LOADING_ACTION(true));
    }

    let response: any;
    const backAction = backButtonTrigger();
    const getStoreDataPromise_init = getStoreData();
    const fetchedData: any = yield getStoreDataPromise_init;

    if (!backAction) {
      const response: any = yield call(
        productsService.fetchProductsByCategory,
        action.payload
      );
      setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', response);
      if (action.payload.pageNumber >= 2) {
        // Handle cachedProductList here if needed
        let cachedProductList: any[] = [];
        if (fetchedData) {
          cachedProductList = [...fetchedData];
        }
        cachedProductList.push(response.catalogEntryView);
        const dataToAdd = cachedProductList;
        yield update(dataToAdd);
      } else {
        const dataToAdd = [response.catalogEntryView];
        yield addData(dataToAdd);
      }
      yield put(
        UPDATE_FACETS_SUCCESS_ACTION({
          response,
          pageNumber: action.payload.pageNumber,
          userType: action.payload.userType,
          searchPlp: false,
        })
      );
    } else {
      let cachedProductList: any[] = [];
      if (fetchedData) {
        cachedProductList = [...fetchedData];
      }
      if (cachedProductList.length < action.payload.pageNumber) {
        const { siteResponse }: any = yield call(
          productsService.fetchProductsByCategory,
          action.payload
        );
        response = siteResponse;
        setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', siteResponse);
        cachedProductList.push(response.catalogEntryView);
        yield update(cachedProductList);
      } else {
        response = getLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION');
      }
      yield put(
        UPDATE_FACETS_SUCCESS_ACTION({
          response,
          pageNumber: action.payload.pageNumber,
          searchPlp: false,
          cachedProductList: cachedProductList,
          userType: action.payload.userType,
          isback: true,
        })
      );
    }

    //setLocalStorage('Back', 'false');
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));

      return;
    }

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* getProductListForStore(action: any): Generator {
  try {
    let response: any;
    const backAction = backButtonTrigger();
    const getStoreDataPromise_init = getStoreData();
    const fetchedData: any = yield getStoreDataPromise_init;
    let previousData: any;
    if (!backAction) {
      response = yield call(
        productsService.fetchProductsByStoreIdentifier,
        action.payload
      );

      setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', response);
      if (action.payload.pageNumber >= 2) {
        // Handle cachedProductList here if needed
        let cachedProductList: any[] = [];
        if (fetchedData) {
          cachedProductList = [...fetchedData];
        }
        cachedProductList.push(response.catalogEntryView);
        const dataToAdd = cachedProductList;
        yield update(dataToAdd);
      } else {
        const dataToAdd = [response.catalogEntryView];
        yield addData(dataToAdd);
        previousData = [response.catalogEntryView];
      }
    } else {
      let cachedProductList: any[] = [];
      if (fetchedData) {
        cachedProductList = [...fetchedData];
      }
      if (cachedProductList.length < action.payload.pageNumber) {
        const { siteResponse }: any = yield call(
          productsService.fetchProductsByCategory,
          action.payload
        );
        response = siteResponse;
        setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', siteResponse);
        cachedProductList.push(response.catalogEntryView);
        yield update(cachedProductList);
      } else {
        response = getLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION');
      }
    }

    const getStoreDataPromise = getStoreData();
    previousData = yield getStoreDataPromise;

    yield put(
      GET_PRODUCT_LIST_SUCCESS_ACTION({
        ...response,
        userType: action.payload.userType,
        searchPlp: false,
        pageNumber: action.payload.pageNumber,
        previousData: previousData,
      })
    );
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));

      return;
    }

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* filterProductListForShopStore(action: any): Generator {
  try {
    const pageNumber = action.payload.pageNumber;

    if (pageNumber === 0) {
      yield put(FACET_LOADING_ACTION(true));
    } else {
      yield put(SET_PLP_PAGE_LOADING_ACTION(true));
    }

    const response = yield call(
      productsService.fetchProductsByStoreIdentifier,
      action.payload
    );

    yield put(
      UPDATE_FACETS_SUCCESS_ACTION({
        response,
        pageNumber: action.payload.pageNumber,
        searchPlp: false,
      })
    );
  } catch (e: any) {
    const axiosError: AxiosError = e as AxiosError;

    const { response } = axiosError;

    if (response?.status === 500) {
      yield put(SHOW_PLP_ERROR_ACTION(true));

      return;
    }

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* getPrivateBrands(action: any): Generator {
  try {
    const response = yield call(
      productsService.fetchPrivateBrands,
      action.payload
    );

    yield put(FETCH_PRIVATE_BRANDS_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);
  }
}

export function* getProductListByKeyword(action: any): Generator {
  try {
    let siteResponse_, isPdpRedirect_: any;
    const getStoreDataPromise = fetchData();
    const fetchedData: any = yield getStoreDataPromise;
    let previousData: any;
    let repeatedCall = false;
    repeatedCall = recentlyVisitedLinks(action.payload);
    if (!repeatedCall) {
      const { siteResponse, isPdpRedirect }: any = yield call(
        searchProductsContentService.fetchProductsBySearchTerm,
        action.payload
      );
      siteResponse_ = siteResponse;
      isPdpRedirect_ = isPdpRedirect;
      setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', siteResponse_);
      setLocalStorage('LAST_ACTIONCALLED', action.payload);

      setLocalStorage('isPdpRedirect', isPdpRedirect_);

      if (action.payload.pageNumber >= 2) {
        let cachedProductList: any;
        if (fetchedData) {
          cachedProductList = [...fetchedData];
        }
        cachedProductList.push(siteResponse_.catalogEntryView);

        const dataToAdd = cachedProductList;
        yield update(dataToAdd);

        previousData = cachedProductList;
      } else {
        const dataToAdd = [siteResponse_.catalogEntryView];
        yield addData(dataToAdd);
        previousData = [siteResponse_.catalogEntryView];
      }
    } else {
      let cachedProductList: any[] = [];
      if (fetchedData) {
        cachedProductList = [...fetchedData];
      }

      console.log('data fetched', fetchedData);

      if (cachedProductList.length < action.payload.pageNumber) {
        const { siteResponse, isPdpRedirect }: any = yield call(
          searchProductsContentService.fetchProductsBySearchTerm,
          action.payload
        );
        siteResponse_ = siteResponse;
        isPdpRedirect_ = isPdpRedirect;

        setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', siteResponse_);

        cachedProductList.push(siteResponse_.catalogEntryView);
        yield update(cachedProductList);
      } else {
        siteResponse_ = getLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION');
      }
    }

    if (!isPdpRedirect_) {
      const getStoreDataPromise = fetchData();
      let fetchedData = yield getStoreDataPromise;
      previousData = fetchedData;
      yield put(
        GET_PRODUCT_LIST_SUCCESS_ACTION({
          ...siteResponse_,
          searchPlp: true,
          searchTerm: action.payload.searchKeyword,
          pageNumber: action.payload.pageNumber,
          previousData: previousData,
        })
      );
    }

    if (isPdpRedirect_ && siteResponse_) {
      const parentCatalogEntryID =
        siteResponse_.contents[0].parentCatalogEntryID;
      if (parentCatalogEntryID) {
        const payload = {
          id: [parentCatalogEntryID],
          storeID: action.payload.storeID,
        };

        const response: any = yield call(
          productsService.fetchProductById,
          payload
        );

        yield put(REDIRECT_TO_PDP_ACTION(response.contents[0].seo.href));
      } else {
        yield put(REDIRECT_TO_PDP_ACTION(siteResponse_.contents[0].seo.href));
      }
    }
  } catch (e: any) {
    if (e?.message !== CANCEL_ON_UNMOUNT) {
      yield put(NO_SEARCH_RESULTS_FOUND_ACTION());
    }
  }
}

export function* updateProductListByKeyword(action: any): Generator {
  try {
    const pageNumber = action.payload.pageNumber;
    const getStoreDataPromise = fetchData();
    const fetchedData: any = yield getStoreDataPromise;

    if (pageNumber === 0) {
      yield put(FACET_LOADING_ACTION(true));
    } else {
      yield put(SET_PLP_PAGE_LOADING_ACTION(true));
    }

    let response: any;

    const lastCall: any = cloneDeep(
      getLocalStorage('LAST_ACTIONCALLED_UPDATE') || {}
    );
    const payloadcall = cloneDeep(action.payload);
    let pageNumberLogic = false;
    let repeatedCall = false;
    if (getLocalStorage('LAST_ACTIONCALLED_UPDATE')) {
      const beforepagenumberlogic =
        JSON.stringify(lastCall) === JSON.stringify(payloadcall);
      pageNumberLogic = beforepagenumberlogic
        ? beforepagenumberlogic
        : payloadcall?.pageNumber < lastCall?.pageNumber;

      const hasOrderAttr =
        has(payloadcall, 'orderBy') && has(lastCall, 'orderBy');
      if (hasOrderAttr) {
        lastCall.orderBy = Number(lastCall.orderBy);
      }
      lastCall.pageNumber = '';
      payloadcall.pageNumber = '';
      const backcall = payloadcall?.isBack;
      if (payloadcall?.isBack) delete payloadcall.isBack;
      // const prevReq = isEqual(lastCall, payloadcall); // JSON.stringify(lastCall) === JSON.stringify(payloadcall);
      const prevReq = JSON.stringify(lastCall) === JSON.stringify(payloadcall);
      repeatedCall = prevReq && pageNumberLogic;
      repeatedCall = backcall || (prevReq && pageNumberLogic);
    }

    if (!repeatedCall) {
      const { siteResponse }: any = yield call(
        searchProductsContentService.fetchProductsBySearchTerm,
        action.payload
      );
      setLocalStorage('LAST_ACTIONCALLED_UPDATE', action.payload);
      response = siteResponse;
      setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', response);
      if (action.payload.pageNumber >= 2) {
        let cachedProductList: any[] = [];
        if (fetchedData) {
          cachedProductList = [...fetchedData];
        }
        if (action.payload.pageNumber === 2) {
          // cachedProductList = [cachedProductList];
          cachedProductList.push(response.catalogEntryView);
        } else {
          cachedProductList.push(response.catalogEntryView);
        }
        const dataToAdd = cachedProductList;
        yield update(dataToAdd);
      } else {
        const dataToAdd = [response.catalogEntryView];
        yield addData(dataToAdd);
      }

      yield put(
        UPDATE_FACETS_SUCCESS_ACTION({
          response,
          pageNumber: action.payload.pageNumber,
          searchPlp: true,
        })
      );
    } else {
      let cachedProductList: any[] = [];
      if (fetchedData) {
        cachedProductList = [...fetchedData];
      }

      /*if (cachedProductList.length < pageNumber) {
        const { siteResponse }: any = yield call(
          searchProductsContentService.fetchProductsBySearchTerm,
          action.payload
        );
        response = siteResponse;
        setLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION', response);
        if (action.payload.pageNumber == 2) {
          //cachedProductList = [cachedProductList];
          cachedProductList.push(response.catalogEntryView);
        } else {
          cachedProductList.push(response.catalogEntryView);
        }
        yield addData(cachedProductList);
        yield put(
          UPDATE_FACETS_SUCCESS_ACTION({
            response,
            pageNumber: action.payload.pageNumber,
            searchPlp: true,
            cachedProductList: cachedProductList,
          })
        );
      }*/
      response = getLocalStorage('GET_PRODUCT_LIST_SUCCESS_ACTION');
      yield put(
        UPDATE_FACETS_SUCCESS_ACTION({
          response,
          pageNumber: action.payload.pageNumber,
          searchPlp: true,
          cachedProductList: cachedProductList,
          isback: true,
        })
      );
      //if(cachedFacets.grou)
    }

    // const { siteResponse }: any = yield call(
    //   searchProductsContentService.fetchProductsBySearchTerm,
    //   action.payload
    // );
  } catch (e: any) {
    const { response }: AxiosError = e as AxiosError;

    if (e.message !== CANCEL_ON_UNMOUNT) {
      yield put(
        REDIRECT_TO_ERROR_PAGE_ACTION({
          errorCode: response?.status.toString(),
          errorKey: response?.config.url,
          errorMessage: response?.data,
          redirectToErrorPage: true,
        })
      );
    }
  }
}

export function* getSearchDisplayByKeyword(action: any): Generator {
  try {
    const response: any = yield call(
      searchProductsContentService.fetchSearchDisplayBySearchTerm,
      action.payload
    );

    const { redirecturl, viewTaskName, storeId } = response;

    const searchDiaplayResponse = {
      redirecturl: redirecturl,
      viewTaskName: viewTaskName,
      storeId: storeId,
      searchTerm: formatSearchTerm(action.payload.term),
    };

    yield put(SEARCH_DISPLAY_SUCCESS_ACTION(searchDiaplayResponse));

    if (!redirecturl && action.payload?.searchProductParams) {
      yield put(
        SEARCH_PRODUCT_LIST_ACTION(action.payload?.searchProductParams)
      );
    }

    return response;
  } catch (e) {
    console.error(e);
  }
}

export function* getInventoryInfo(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchInventoryInfo,
      action.payload
    );

    yield put(FETCH_INVENTORY_INFO_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);
  }
}

export function* getSubCategories(action: any): Generator {
  try {
    const response: any = yield call(
      categoryService.fetchAllSubcategoriesForCarousel,
      action.payload
    );

    if (Object.keys(response)) {
      yield put(FETCH_SUB_CATEGORIES_SUCCESS_ACTION(response));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* getCategoryByIdentifier(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchCategoryByIdentifier,
      action.payload
    );

    yield put(FETCH_INVENTORY_BY_IDENTIFIER_SUCCESS_ACTION(response));
  } catch (e) {
    console.error(e);
  }
}

export function* getBrandByIdentifier(action: any): Generator {
  try {
    const response: any = yield call(
      productsService.fetchCategoryByIdentifier,
      action.payload
    );

    if (response && response.contents && response.contents[0]) {
      yield put(CURRENT_BRAND_ID_ACTION(response.contents[0].id));

      yield put(
        CURRENT_BRAND_IDENTIFIER_ACTION(response.contents[0].identifier)
      );

      yield put(
        CURRENT_BRAND_INFO_ACTION({
          brandName: response.contents[0].name,
          seo: response.contents[0].seo.href,
        })
      );
    }
  } catch (e) {
    console.error(e);
  }
}
